/* You can add global styles to this file, and also import other style files */
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

body {
  margin: 0;
  height: 100%;
}
html {
  scroll-behavior: smooth;
  height: 100%;
}
